/*  THEME COLORs
========================================================================== */
/* Looks good on chrome default color profile */
$color-primary:						#886ab5;
$color-success:						#1dc9b7;
$color-info:						#2196F3;
$color-warning:						#ffc241;
$color-danger:						#fd3995;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); 

/* We will manually convert these primary colors to rgb for the dark mode option of the theme */
$rgb-primary:						hexToRGBString($color-primary) !default;
$rgb-success:						hexToRGBString($color-success) !default;
$rgb-info:							hexToRGBString($color-info) !default;
$rgb-warning:						hexToRGBString($color-warning) !default;
$rgb-danger:						hexToRGBString($color-danger) !default;
$rgb-fusion:						hexToRGBString($color-fusion) !default; 

/* looks good in sRGB but washed up on chrome default 
$color-primary:						#826bb0;
$color-success:						#31cb55;
$color-info:						#5e93ec;
$color-warning:						#eec559;
$color-danger:						#dc4b92;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); */

/*  Color Polarity
========================================================================== */
$white:								#fff !default;
$black:								#000 !default;
$disabled:							darken($white, 20%) !default;

/*  PAINTBUCKET MIXER
========================================================================== */
/* the grays */ 
$gray-50:							#f9f9f9;
$gray-100:							#f8f9fa;
$gray-200:							#f3f3f3;
$gray-300:							#dee2e6;
$gray-400:							#ced4da;
$gray-500:							#adb5bd;
$gray-600:							#868e96;
$gray-700:							#495057;
$gray-800:							#343a40;
$gray-900:							#212529;

/* the sapphires */
$primary-50:						lighten($color-primary, 25%) !default;	
$primary-100:						lighten($color-primary, 20%) !default;	
$primary-200:						lighten($color-primary, 15%) !default;	
$primary-300:						lighten($color-primary, 10%) !default;	
$primary-400:						lighten($color-primary, 5%) !default;
$primary-500:						$color-primary !default;
$primary-600:						darken($color-primary, 5%) !default;
$primary-700:						darken($color-primary, 10%) !default;
$primary-800:						darken($color-primary, 15%) !default;
$primary-900:						darken($color-primary, 20%) !default;

/* the emeralds */
$success-50:						lighten($color-success, 25%) !default;	
$success-100:						lighten($color-success, 20%) !default;	
$success-200:						lighten($color-success, 15%) !default;	
$success-300:						lighten($color-success, 10%) !default;	
$success-400:						lighten($color-success, 5%) !default;
$success-500:						$color-success !default;
$success-600:						darken($color-success, 5%) !default;
$success-700:						darken($color-success, 10%) !default;
$success-800:						darken($color-success, 15%) !default;
$success-900:						darken($color-success, 20%) !default;

/* the amethyths */
$info-50:							lighten($color-info, 25%) !default;	
$info-100:							lighten($color-info, 20%) !default;	
$info-200:							lighten($color-info, 15%) !default;	
$info-300:							lighten($color-info, 10%) !default;	
$info-400:							lighten($color-info, 5%) !default;
$info-500:							$color-info !default;
$info-600:							darken($color-info, 5%) !default;
$info-700:							darken($color-info, 10%) !default;
$info-800:							darken($color-info, 15%) !default;
$info-900:							darken($color-info, 20%) !default;

/* the topaz */
$warning-50:						lighten($color-warning, 25%) !default;	
$warning-100:						lighten($color-warning, 20%) !default;	
$warning-200:						lighten($color-warning, 15%) !default;	
$warning-300:						lighten($color-warning, 10%) !default;	
$warning-400:						lighten($color-warning, 5%) !default;
$warning-500:						$color-warning !default;
$warning-600:						darken($color-warning, 5%) !default;
$warning-700:						darken($color-warning, 10%) !default;
$warning-800:						darken($color-warning, 15%) !default;
$warning-900:						darken($color-warning, 20%) !default;

/* the rubies */
$danger-50:							lighten($color-danger, 25%) !default;	
$danger-100:						lighten($color-danger, 20%) !default;	
$danger-200:						lighten($color-danger, 15%) !default;	
$danger-300:						lighten($color-danger, 10%) !default;	
$danger-400:						lighten($color-danger, 5%) !default;
$danger-500:						$color-danger !default;
$danger-600:						darken($color-danger, 5%) !default;
$danger-700:						darken($color-danger, 10%) !default;
$danger-800:						darken($color-danger, 15%) !default;
$danger-900:						darken($color-danger, 20%) !default;

/* the graphites */
$fusion-50:							lighten($color-fusion, 25%) !default;	
$fusion-100:						lighten($color-fusion, 20%) !default;	
$fusion-200:						lighten($color-fusion, 15%) !default;	
$fusion-300:						lighten($color-fusion, 10%) !default;	
$fusion-400:						lighten($color-fusion, 5%) !default;
$fusion-500:						$color-fusion !default;
$fusion-600:						darken($color-fusion, 5%) !default;
$fusion-700:						darken($color-fusion, 10%) !default;
$fusion-800:						darken($color-fusion, 15%) !default;
$fusion-900:						darken($color-fusion, 20%) !default;

$theme-colors-extended: () !default;
$theme-colors-extended: map-merge((
	"rgb-primary":					$rgb-primary,
	"rgb-success":					$rgb-success,
	"rgb-info":						$rgb-info,
	"rgb-warning":					$rgb-warning,
	"rgb-danger":					$rgb-danger,
	"rgb-fusion":					$rgb-fusion,
	"primary-50":					$primary-50,
	"primary-100":					$primary-100,
	"primary-200":					$primary-200,
	"primary-300":					$primary-300,
	"primary-400":					$primary-400,
	"primary-500":					$primary-500,
	"primary-600":					$primary-600,
	"primary-700":					$primary-700,
	"primary-800":					$primary-800,
	"primary-900":					$primary-900,
	"success-50":					$success-50,
	"success-100":					$success-100,
	"success-200":					$success-200,
	"success-300":					$success-300,
	"success-400":					$success-400,
	"success-500":					$success-500,
	"success-600":					$success-600,
	"success-700":					$success-700,
	"success-800":					$success-800,
	"success-900":					$success-900,
	"info-50":						$info-50,
	"info-100":						$info-100,
	"info-200":						$info-200,
	"info-300":						$info-300,
	"info-400":						$info-400,
	"info-500":						$info-500,
	"info-600":						$info-600,
	"info-700":						$info-700,
	"info-800":						$info-800,
	"info-900":						$info-900,
	"warning-50":					$warning-50,
	"warning-100":					$warning-100,
	"warning-200":					$warning-200,
	"warning-300":					$warning-300,
	"warning-400":					$warning-400,
	"warning-500":					$warning-500,
	"warning-600":					$warning-600,
	"warning-700":					$warning-700,
	"warning-800":					$warning-800,
	"warning-900":					$warning-900,  
	"danger-50":					$danger-50,
	"danger-100":					$danger-100,
	"danger-200":					$danger-200,
	"danger-300":					$danger-300,
	"danger-400":					$danger-400,
	"danger-500":					$danger-500,
	"danger-600":					$danger-600,
	"danger-700":					$danger-700,
	"danger-800":					$danger-800,
	"danger-900":					$danger-900,
	"fusion-50":					$fusion-50,
	"fusion-100":					$fusion-100,
	"fusion-200":					$fusion-200,
	"fusion-300":					$fusion-300,
	"fusion-400":					$fusion-400,
	"fusion-500":					$fusion-500,
	"fusion-600":					$fusion-600,
	"fusion-700":					$fusion-700,
	"fusion-800":					$fusion-800,
	"fusion-900":					$fusion-900

), $theme-colors-extended);

/*  Define universal border difition (div outlines, etc)
========================================================================== */
$theme-border-utility-size:				0px;

/*  MOBILE BREAKPOINT & GUTTERS (contains some bootstrap responsive overrides)
========================================================================== */
$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 576px,
	// Medium screen / tablet
	md: 768px,
	// Large screen / desktop
	lg: 992px, // also change 'mobileResolutionTrigger' in app.config.js
	// Decently size screen / wide laptop
	xl: 1399px 
);

$mobile-breakpoint:						lg !default;                               /* define when mobile menu activates, here we are declearing (lg) so it targets the one after it */
$mobile-breakpoint-size:				map-get($grid-breakpoints, lg) !default;   /* bootstrap reference xs: 0,  sm: 544px, md: 768px, lg: 992px, xl: 1200px*/
//$mobile-font-size:						15px; 	                                   /* bigger fontsize for mobile screens */
$grid-gutter-width-base:				3rem;
$grid-gutter-width:						1.5rem;

$grid-gutter-widths: (
	xs: $grid-gutter-width-base / 2,         
	sm: $grid-gutter-width-base / 2,          
	md: $grid-gutter-width-base / 2,        
	lg: $grid-gutter-width-base / 2,        
	xl: $grid-gutter-width-base / 2        
);


/* global var used for spacing*/
$spacer:                  1rem;
$spacers: () ;
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 2),
		6: ($spacer * 2.5)
	),
	$spacers
);

/* Uniform Padding variable */
/* Heads up! This is a global scoped variable - changing may impact the whole template */
$p-1:									0.25rem;
$p-2:									0.5rem;
$p-3:									1rem;
$p-4:									1.5rem;
$p-5:									2rem;


/*   BOOTSTRAP OVERRIDES (bootstrap variables)
========================================================================== */ 
$grays: (
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900
);

$colors: (
	"blue": $color-primary,
	"red": $color-danger,
	"orange": $color-warning,
	"yellow": $color-warning,
	"green": $color-success,
	"white": $white,
	"gray": $gray-600,
	"gray-dark": $gray-700
);

/* usage: theme-colors("primary"); */
$theme-colors: (
	"primary": $color-primary,
	"secondary": $gray-600,
	"success": $color-success,
	"info": $color-info,
	"warning": $color-warning,
	"danger": $color-danger,
	"light": $white,
	"dark": $fusion-500
);

/* forms */
/*$input-height:							calc(2.25rem + 1px); //I had to add this because the input gruops was having improper height for some reason... */
$input-border-color:					#E5E5E5;
$input-focus-border-color:				$color-primary;
$input-btn-focus-color:					transparent;
$input-padding-y:						.5rem;  
$input-padding-x:						.875rem;
$label-margin-bottom:					.3rem;
$form-group-margin-bottom:				1.5rem;

/* links */
$link-color:							$primary-500;
$link-hover-color:						$primary-400;

/* checkbox */ 
$custom-control-indicator-size:					1.125rem;
$custom-checkbox-indicator-border-radius:		2px;
$custom-control-indicator-border-width: 		2px;
$custom-control-indicator-bg-size:				0.5rem;

/*$custom-file-height-inner:				calc(2.25rem - 1px);*/
//$custom-file-padding-y:					$input-padding-y;

/* not part of bootstrap variable */
$custom-control-indicator-bg-size-checkbox:  50% 50% !default;

/* custom checkbox */
// the checkbox needs to be a little darker for input groups
$custom-control-indicator-checked-bg:				$primary-600;
$custom-control-indicator-checked-border-color: 	$primary-700;
$custom-control-indicator-checked-disabled-bg:		$primary-100;

$custom-control-indicator-active-bg:				$primary-100; 
$custom-control-indicator-active-border-color:		$primary-100;
$custom-control-indicator-active-color:				$primary-100;

$custom-control-indicator-focus-border-color:		$primary-400;
$custom-select-focus-border-color:					$primary-500;

$custom-checkbox-indicator-indeterminate-border-color: $primary-500;
$custom-checkbox-indicator-indeterminate-bg: $primary-500;


/* custom range */
$custom-range-thumb-width:				1rem;
$custom-range-thumb-border-radius:		50%;
$custom-range-track-height:				0.325rem;
$custom-range-thumb-bg:					$primary-500;
$custom-range-thumb-active-bg:			$primary-300;
$custom-range-thumb-focus-box-shadow:	0 0 0 1px $white, 0 0 0 0.2rem rgba($primary-500, 0.25);

/* custom file */
$custom-file-focus-border-color:		$primary-500;

/* badge */
$badge-font-size:						85%;
$badge-font-weight:						500;

/* cards */
$card-spacer-y:							1rem;
$card-spacer-x:							1rem;
$card-cap-bg:							inherit;
$card-border-color:						rgba(0, 0, 0, 0.08);
$list-group-border-color:				$card-border-color;

/*border radius*/
$border-radius:							4px;
$border-radius-lg:						$border-radius;
$border-radius-sm:						$border-radius;
$border-radius-plus:					10px;

/* alert */
$alert-padding-y:						1rem;
$alert-padding-x:						1.25rem;
$alert-margin-bottom:					$grid-gutter-width + 0.5rem;

/* toast */
$toast-padding-y:						0.5rem;
$toast-padding-x:						0.75rem;
$toast-header-color:					$fusion-500;

/* breadcrumb */
$breadcrumb-bg:							lighten($fusion-50, 40%);
$breadcrumb-divider-color:				inherit;

/* input button */
$input-btn-padding-y-sm:				.375rem;
$input-btn-padding-x-sm:				.844rem;

$input-btn-padding-y:					.5rem;
$input-btn-padding-x:					1.125rem;

$input-btn-padding-y-lg:				.75rem;
$input-btn-padding-x-lg:				1.5rem;

/* nav link */
$nav-link-padding-y:					$input-btn-padding-y;
$nav-link-padding-x:					$input-btn-padding-x;

/* nav, tabs, pills */
$nav-tabs-border-color:					rgba($black, 0.1);
$nav-tabs-link-active-border-color:		rgba($black, 0.1) rgba($black, 0.1) $white;
$nav-tabs-link-hover-border-color:		rgba($black, 0.07) rgba($black, 0.07) transparent;

/* tables */
$table-border-color:					lighten(desaturate($primary-500, 60%), 35%); //rgba($black, 0.09);
$table-hover-bg:						lighten(desaturate($primary-900, 70%), 63%);
$table-accent-bg:						rgba($fusion-500,.02);
$table-dark-bg:							$fusion-300;
$table-dark-border-color:				$fusion-400;
$table-dark-accent-bg:					rgba($white, .05);
$table-dark-hover-bg:					$color-primary;

/* dropdowns */
$dropdown-border-width:					$theme-border-utility-size; 
$dropdown-padding-y:					.3125rem;
$dropdown-item-padding-y:				.75rem;
$dropdown-item-padding-x:				1.5rem; 
$dropdown-link-active-bg:				lighten($primary-50, 13%);  
$dropdown-link-active-color:			$primary-900;
$dropdown-link-hover-color:				$primary-700;

/* dropdowns sizes */
$dropdown-xl-width:						21.875rem !default;
$dropdown-lg-width:						17.5rem !default;
$dropdown-md-width:						14rem !default;
$dropdown-sm-width:						8rem !default;
$dropdown-shadow:						0 0 15px 1px rgba(desaturate($primary-900, 20%), (20/100));   

/* popovers */
$popover-border-color:					rgba(0, 0, 0, 0.2);
$popover-header-padding-y:				1rem;
$popover-header-padding-x:				1rem;
$popover-header-bg:						transparent;
$popover-border-width:					3px;
$popover-arrow-width:					15px;
$popover-arrow-height:					7px;
$popover-arrow-outer-color:				inherit;
$popover-arrow-color:					transparent;
$popover-font-size:						14px;
$popover-box-shadow:					1px 0 13px rgba(90, 80, 105, 0.2);
$popover-border-radius:					0.5rem;

/* tooltips */
$tooltip-max-width:						200px;
$tooltip-color:							$white;
$tooltip-bg:							rgba($fusion-700, 0.9);
$tooltip-border-radius:					5px;
$tooltip-opacity:						1;
$tooltip-padding-y:						.3rem;
$tooltip-padding-x:						.6rem;
$tooltip-margin:						2px;
$tooltip-arrow-width:					8px;
$tooltip-arrow-height:					5px;

/* modal */
$modal-header-padding-y:				1.25rem;
$modal-header-padding-x:				1.25rem;
$modal-header-padding:					$modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-inner-padding:					1.25rem;
$modal-backdrop-opacity:				0.2;
$modal-content-border-color:			transparent;
$modal-header-border-width:				0px;
$modal-footer-border-width:				0px;

//$modal-lg:								650px;

/* reference guide
http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
8px = 0.5rem
9px = 0.5625rem
10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem (base)
17px = 1.0625rem
18px = 1.125rem
19px = 1.1875rem
20px = 1.25rem
21px = 1.3125rem
22px = 1.375rem
24px = 1.5rem
25px = 1.5625rem
26px = 1.625rem
28px = 1.75rem
30px = 1.875rem
32px = 2rem
34px = 2.125rem
36px = 2.25rem
38px = 2.375rem
40px = 2.5rem
*/

/* Fonts */
$font-size-base:						0.8125rem;
$font-size-lg:							1rem;
$font-size-sm:							0.75rem;
$line-height-base:						1.47;
$headings-line-height:					1.57;

$h1-font-size:							1.5rem;
$h2-font-size:							1.375rem;
$h3-font-size:							1.1875rem;
$h4-font-size:							1.0625rem;
$h5-font-size:							0.9375rem;
$h6-font-size:							0.875rem;

$display1-size:							5rem;
$display2-size:							4.5rem;
$display3-size:							3.5rem;
$display4-size:							2.5rem;

$navbar-toggler-font-size:				21px;
$navbar-toggler-padding-y:				7.5px; 
$navbar-toggler-padding-x:				18px;

/* carousel */
$carousel-indicator-height:				13px;
$carousel-indicator-width:				13px;

/*  BASE VARS
========================================================================== */
// usage: background-image: url("#{$baseURL}img/bg.png"); 

$baseURL:								"../" !default;
$webfontsURL:							"../webfonts" !default;
$base-text-color:						darken($white,60%) !default;

/* font vars below will auto change to rem values using function rem($value)*/
$fs-base:								13px !default;
$fs-nano:								$fs-base - 2;   /* 11px   */
$fs-xs: 								$fs-base - 1;   /* 12px   */
$fs-sm: 								$fs-base - 0.5; /* 12.5px */
$fs-md: 								$fs-base + 1;   /* 14px   */
$fs-lg: 								$fs-base + 2;   /* 15px   */
$fs-xl: 								$fs-base + 3;   /* 16px   */
$fs-xxl: 								$fs-base + 15;  /* 28px   */

/*  Font Family
========================================================================== */
										/*hint: you can also try the font called 'Poppins' by replacing the font 'Roboto' */
$font-import:							"https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" !default;
$page-font:								"Roboto", 'Helvetica Neue', Helvetica, Arial !default;
$nav-font:								$page-font !default;
$heading-font-family:					$page-font !default; 
$mobile-page-font:						'HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue',Helvetica,Arial,sans-serif;

/*  ANIMATIONS
========================================================================== */
$nav-hide-animate: 						all 470ms cubic-bezier(0.34, 1.25, 0.3, 1) !default;		/* this addresses all animation related to nav hide to nav minify */

/*  Z-INDEX declearation
========================================================================== */
$space:									1000 !default;
$cloud:									950 !default;
$ground:								0 !default;
$water:									-99 !default;
/* we adjust bootstrap z-index to be higher than our higest z-index*/
$zindex-dropdown:						$space + 1000;
$zindex-sticky:							$space + 1020;
$zindex-fixed:							$space + 1030;
$zindex-modal-backdrop:					$space + 1040;
$zindex-modal:							$space + 1050;
$zindex-panel-fullscreen:				$space + 1055;
$zindex-popover:						$space + 1060;
$zindex-tooltip:						$space + 1070;

/*  CUSTOM ICON PREFIX 
========================================================================== */
$cust-icon-prefix:						ni;

/*  PRINT CSS (landscape or portrait)
========================================================================== */
$print-page-type: 						portrait; 												  /* landscape or portrait */
$print-page-size:						letter;													  /* auto, letter */
$print-page-margin:						1.0cm;

/*  Common Element Variables
========================================================================== */
$body-background-color:					$white !default;
$page-bg:								desaturate(lighten($primary-500, 41.7%), 5%)  !default; //#f9f9fc

/* Z-index decleartion "birds eye view"
========================================================================== */
$depth:									999 !default;
$depth-header:							$depth + 1 !default;
$depth-nav:								$depth-header + 2 !default;

/*  Components
========================================================================== */
$frame-border-color:					#f7f9fa !default;

/*  PAGE HEADER STUFF
========================================================================== */

/* colors */
$header-bg:								$white !default;
$header-border-color:					#ccc !default;
$header-border-bottom-color:			rgba(darken($primary-700, 10%), (13/100)) !default;		
$header-link-color:						$primary-500 !default;
$header-link-hover-color:				darken($header-bg, 75%) !default;

/* height */
$header-height:							4.125rem !default;
$header-height-nav-top:					4.125rem !default;
$header-inner-padding-x:				2rem !default;
$header-inner-padding-y:				0 !default;

/* logo */
$header-logo-border-bottom:				rgba(darken($primary-700, 10%), (30/100)) !default;
$header-logo-width:						28px !default; 										  /* try not to go beywond the width of $main_nav_width value */
$header-logo-height:					28px !default; 										  /* you may need to change this depending on your logo design */
$header-logo-text-align:				center; 											  /* adjust this as you see fit : left, right, center */

/* icon font size (not button) */
$header-icon-size:						21px;

/* search input box */
$header-search-border-color:			transparent !default;								  /* suggestion: #ccced0*/
$header-search-bg:						transparent !default;
$header-search-width:					25rem !default;
$header-search-height:					$header-height - 1.5rem !default; 
$header-search-font-size:				$fs-base + 2;
$header-search-padding:					$spacer * 0.38;

/* btn */
$header-btn-active-bg:					$fusion-500 !default;
$header-btn-color:						darken($header-bg, 35%) !default;
$header-btn-hover-color:				$header-link-hover-color !default;
$header-btn-active-color:				$white !default;
$header-btn-height: 					$header-height/2 + 0.1875rem !default;
$header-btn-width: 						3.25rem !default;
$header-btn-font-size:					21px !default; //works only for font icons
$header-btn-border-radius:				$border-radius !default;
$header-non-btn-width:					3.125rem !default;
$header-dropdown-arrow-color:			$primary-700 !default;

/* dropdown: app list */
$header-applist-link-block-height:		5.9375rem;
$header-applist-link-block-width:		6.25rem;
$header-applist-rows-width:				21.875rem;
$header-applist-rows-height:			22.5rem; 
$header-applist-box-padding-x:			$p-2;
$header-applist-box-padding-y:			$p-3;
$header-applist-icon-size:				3.125rem;

/* badge */
$header-badge-min-width:				1.25rem !default;
$header-badge-left:						1.5625rem !default;
$header-badge-top:						($header-height / 2 - $header-badge-min-width) + 0.28125rem !default; 

/* COMPONENTS & MODS */
$nav-tabs-clean-link-height:			45px !default;

/*  NAVIGATION STUFF

Guide:

aside.page-sidebar ($nav-width, $nav-background)
	.page-logo
	.primary-nav
		.info-card
		ul.nav-menu
			li
				a (parent level-0..., $nav-link-color, $nav-link-hover-color, $nav-link-hover-bg-color, $nav-link-hover-left-border-color)
					icon 
					span
					collapse-sign 
					
				ul.nav-menu-sub-one  
					li
						a ($nav-level-1... $nav-sub-link-height)
							span
							collapse-sign

						ul.nav-menu-sub-two
							li
								a ($nav-level-2... $nav-sub-link-height)
									span

		p.nav-title ($nav-title-*...)


========================================================================== */

/* main navigation */
/* left panel */
$nav-background:						desaturate($primary-900, 7%) !default;
$nav-background-shade:					rgba(desaturate($info-500, 15%), 0.18) !default;                  
$nav-base-color:						lighten($nav-background, 7%) !default;
$nav-width:								16.875rem !default; 

/* nav footer */
$nav-footer-link-color:					lighten($nav-background, 25%) !default;

/* nav parent level-0 */
$nav-link-color: 						lighten($nav-base-color, 32%) !default;
$nav-font-link-size: 					$fs-base + 1 !default;
$nav-collapse-sign-font-size:			inherit !default;	
$nav-padding-x:							2rem !default; 
$nav-padding-y:							0.8125rem !default;


/* nav link level-1 */
$nav-link-color-child: 					darken($nav-link-color, 5%);
$nav-link-color-child-hover:			$white;

/* nav level-1 bg */
$nav-ul-ul-bg:							rgba($black,0.1);
$nav-ul-padding-top:					10px;
$nav-ul-padding-bottom:					10px;

/* nav icon sizes */
$nav-font-icon-size:					1.125rem !default; //23px for Fontawesome & 20px for NextGen icons
$nav-font-icon-size-sub:				1.125rem !default;

$nav-icon-width:						1.75rem !default;
$nav-icon-margin-right:					0.25rem !default;

/* badge default */
$nav-badge-color: 						$white !default;
$nav-badge-bg-color: 					$danger-500 !default;

/* all child */
$nav-icon-color:						lighten(darken($nav-base-color, 15%),27%) !default;
$nav-icon-hover-color:					lighten(desaturate($color-primary, 30%), 10%) !default;

/* nav title */
$nav-title-color: 						lighten($nav-base-color, 10%) !default;
$nav-title-border-bottom-color: 		lighten($nav-base-color, 3%) !default;
$nav-title-font-size: 					$fs-base - 1.8px;

/* nav Minify */
$nav-minify-hover-bg:					darken($nav-base-color, 3%) !default;
$nav-minify-hover-text:					$white !default;
$nav-minify-width:						4.6875rem !default;
/* when the menu pops on hover */
$nav-minify-sub-width:					$nav-width - ($nav-minify-width - 1.5625rem) !default; 				

/* navigation Width */
/* partial visibility of the menu */
$nav-hidden-visiblity:					0.625rem !default; 											

/* top navigation */
$nav-top-height:						3.5rem !default;
$nav-top-drowndown-width:				13rem !default;
$nav-top-drowndown-background:			$nav-base-color;
$nav-top-drowndown-hover:				rgba($black, 0.1);;
$nav-top-drowndown-color:				$nav-link-color;
$nav-top-drowndown-hover-color:			$white;

/* nav Info Card (appears below the logo) */
$nav-infocard-height:					9.530rem !default;
$profile-image-width:					3.125rem !default; 
$profile-image-width-md:				2rem !default;
$profile-image-width-sm:				1.5625rem !default;
$image-share-height:					2.8125rem !default; /* width is auto */

/* nav DL labels for all child */
$nav-dl-font-size:						0.625rem !default;
$nav-dl-width:							1.25rem !default;
$nav-dl-height:							1rem !default;
$nav-dl-margin-right:					0.9375rem !default;
$nav-dl-margin-left:					$nav-dl-width + $nav-dl-margin-right !default; 	/* will be pulled to left as a negative value */

/*   MISC Settings
========================================================================== */
/* List Table */
$list-table-padding-x:					11px !default;
$list-table-padding-y:					0 !default;

/*   PAGE SETTINGS
========================================================================== */
$settings-incompat-title:				var(--theme-warning-900) !default;
$settings-incompat-desc:				var(--theme-warning-900) !default;
$settings-incompat-bg:					var(--theme-warning-50) !default;
$settings-incompat-border:				var(--theme-warning-700) !default;

/*   PAGE BREADCRUMB 
========================================================================== */
$page-breadcrumb-maxwidth:				200px;

/*   PAGE COMPONENT PANELS 
========================================================================== */
$panel-spacer-y:						1rem;
$panel-spacer-x:						1rem;
$panel-hdr-font-size:					14px;
$panel-hdr-height:						3rem;
$panel-btn-size:						1rem;
$panel-btn-spacing:						0.3rem;
$panel-toolbar-icon:					1.5625rem;
$panel-hdr-background:					$white; //#fafafa;
$panel-edge-radius:						$border-radius;
$panel-placeholder-color:				lighten(desaturate($primary-50, 20%), 10%);

$panel-btn-icon-width:					2rem;
$panel-btn-icon-height:					2rem;
$panel-btn-icon-font-size:				1rem;

/*   PAGE COMPONENT PROGRESSBARS 
========================================================================== */
$progress-height:						.75rem;
$progress-font-size:					.625rem;
$progress-bg:							lighten($fusion-50, 40%);
$progress-border-radius:				10rem;

/*   PAGE COMPONENT MESSENGER 
========================================================================== */
$msgr-list-width:						14.563rem;
$msgr-list-width-collapsed:				3.125rem;
$msgr-get-background:					#f1f0f0;
$msgr-sent-background:					$success-500;
$msgr-animation-delay:					100ms;

/*   FOOTER
========================================================================== */
$footer-bg:								$white !default;
$footer-text-color:						darken($base-text-color, 10%);
$footer-height:							2.8125rem !default;
$footer-font-size:						$fs-base !default;
$footer-zindex:							$cloud - 20 !default;

/*   GLOBALS
========================================================================== */
$mod-main-boxed-width:					map-get($grid-breakpoints, xl);
$slider-width:							15rem;

/* ACCESSIBILITIES */
$enable-prefers-reduced-motion-media-query:   false;

/* SHORTCUT BUTTON (appears on bottom right of the page) */
$app-shortcut-btn-size: 49px;
$menu-item-size: 45px;
$menu-items:5;
$menu-grid-icon: 5px;
$menu-item-direction: 'top'; //top or left


/* GULP WARNINGS */
$ignore-warning: true;