$swal2-white:       $white;
$swal2-black:       $black;

// CONTAINER
$swal2-container-padding: .625em !default;

// BOX MODEL
$swal2-width: 30em;
$swal2-padding: 1.25em !default;
$swal2-border-radius: .3125em !default;
$swal2-box-shadow: #d9d9d9 !default;

// BACKGROUND
$swal2-background: $swal2-white !default;

// TYPOGRAPHY
$swal2-font: inherit !default;
$swal2-font-size: 1rem !default;

// BACKDROP
$swal2-backdrop: rgba($swal2-black, .2);

// ICONS
$swal2-icon-size: 5em !default;
$swal2-icon-margin: 1.25em auto 1.875em !default;
$swal2-icon-zoom: normal !default;
$swal2-success: $success-500;
$swal2-success-border: rgba($swal2-success, .3) !default;
$swal2-error: $danger-500;
$swal2-warning: $warning-500;
$swal2-info: $info-500;
$swal2-question: $primary-500;

// IMAGE
$swal2-image-margin: 1.25em auto !default;

// TITLE
$swal2-title-margin: 0 0 1em;
$swal2-title-color: lighten($swal2-black, 35) !default;
$swal2-title-font-size: 1.0625em;

// CONTENT
$swal2-content-color: $fusion-50;
$swal2-content-font-size: 0.875em;

// INPUT
$swal2-input-margin: 1em auto !default;
$swal2-input-height: 2.625em !default;
$swal2-input-padding: 0 .75em !default;
$swal2-input-border: 1px solid lighten($swal2-black, 85) !default;
$swal2-input-border-radius: .1875em !default;
$swal2-input-border-focus: 1px solid $primary-500;
$swal2-input-box-shadow: rgba($swal2-black, .06) !default;
$swal2-input-box-shadow-focus: transparent;
$swal2-input-font-size: 1.125em !default;
$swal2-input-background: inherit !default;

// TEXTAREA SPECIFIC VARIABLES
$swal2-textarea-height: 6.75em !default;
$swal2-textarea-padding: .75em !default;

// VALIDATION MESSAGE
$swal2-validation-message-justify-content: center !default;
$swal2-validation-message-padding: .625em !default;
$swal2-validation-message-background: lighten($swal2-black, 94) !default;
$swal2-validation-message-color: lighten($swal2-black, 40) !default;
$swal2-validation-message-font-size: 1em !default;
$swal2-validation-message-font-weight: 300 !default;
$swal2-validation-message-icon-background: $swal2-error !default;
$swal2-validation-message-icon-color: $swal2-white !default;
$swal2-validation-message-icon-zoom: normal !default;

// PROGRESS STEPS
$swal2-progress-steps-background: inherit !default;
$swal2-progress-steps-margin: 0 0 1.25em !default;
$swal2-progress-steps-padding: 0 !default;
$swal2-progress-steps-font-weight: 600 !default;
$swal2-progress-steps-distance: 2.5em !default;
$swal2-progress-step-width: 2em;
$swal2-progress-step-height: 2em;
$swal2-progress-step-border-radius: 2em;
$swal2-progress-step-background: $primary-100 !default;
$swal2-progress-step-color: $swal2-white !default;
$swal2-active-step-background: $primary-500;
$swal2-active-step-color: $swal2-white !default;

// FOOTER
$swal2-footer-margin: 1.25em 0 0 !default;
$swal2-footer-padding: 1em 0 0 !default;
$swal2-footer-border-color: #eee !default;
$swal2-footer-color: lighten($swal2-black, 33) !default;
$swal2-footer-font-size: 0.875em;

// ANIMATIONS
$swal2-show-animation: swal2-show .3s !default;
$swal2-hide-animation: swal2-hide .15s forwards !default;
$swal2-success-line-tip-animation: swal2-animate-success-line-tip .75s !default;
$swal2-success-line-long-animation: swal2-animate-success-line-long .75s !default;
$swal2-success-circular-line-animation: swal2-rotate-success-circular-line 4.25s ease-in !default;
$swal2-error-icon-animation: swal2-animate-error-icon .5s !default;
$swal2-error-x-mark-animation: swal2-animate-error-x-mark .5s !default;

// CLOSE BUTTON
$swal2-close-button-width: 1.2em !default;
$swal2-close-button-height: 1.2em !default;
$swal2-close-button-line-height: 1.2 !default;
$swal2-close-button-position: absolute !default;
$swal2-close-button-gap: 0 !default;
$swal2-close-button-transition: color .1s ease-out !default;
$swal2-close-button-border: none !default;
$swal2-close-button-border-radius: 0 !default;
$swal2-close-button-outline: initial !default;
$swal2-close-button-background: transparent !default;
$swal2-close-button-color: lighten($swal2-black, 80) !default;
$swal2-close-button-font-size: 2.5em !default;

// CLOSE BUTTON:HOVER
$swal2-close-button-hover-transform: none !default;
$swal2-close-button-hover-color: $swal2-error !default;

// ACTIONS
$swal2-actions-margin: 1.25em auto 0 !default;

// CONFIRM BUTTON
$swal2-confirm-button-border: 0 !default;
$swal2-confirm-button-border-radius: .25em !default;
$swal2-confirm-button-background-color: $primary-500;
$swal2-confirm-button-color: $swal2-white !default;
$swal2-confirm-button-font-size: 0.875em;

// CANCEL BUTTON
$swal2-cancel-button-border: 0 !default;
$swal2-cancel-button-border-radius: .25em !default;
$swal2-cancel-button-background-color: #aaa !default;
$swal2-cancel-button-color: $swal2-white !default;
$swal2-cancel-button-font-size: $swal2-confirm-button-font-size;

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-darken-hover: rgba($swal2-black, .1) !default;
$swal2-button-darken-active: rgba($swal2-black, .2) !default;
$swal2-button-focus-outline: rgba(50, 100, 150, .4) !default;

// TOASTS
$swal2-toast-close-button-width: .8em !default;
$swal2-toast-close-button-height: .8em !default;
$swal2-toast-close-button-line-height: .8 !default;
$swal2-toast-width: auto !default;
$swal2-toast-padding: .625em !default;
$swal2-toast-title-font-size: 1em !default;
$swal2-toast-content-font-size: 1em !default;
$swal2-toast-input-font-size: 1em !default;
$swal2-toast-validation-font-size: 1em !default;
$swal2-toast-buttons-font-size: 1em !default;
$swal2-toast-footer-margin: .5em 0 0 !default;
$swal2-toast-footer-padding: .5em 0 0 !default;
$swal2-toast-footer-font-size: .8em !default;


.swal2-styled,
.swal2-content {
	font-weight: 400 !important;
}

.swal2-title {
	font-weight: 500 !important;
}

.swal2-popup .swal2-styled:focus {
	box-shadow: none;
}

